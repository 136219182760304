<template>
  <section class="mfo__banner">
    <img
        class="mfo__banner-img"
        src="@/assets/magento/find-out/bg-magento-find-out@2x.jpg"
        alt="What we do to accelerate Magento sites"
        loading="lazy"
        modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
    />
    <h1 class="mfo__banner-title">
      What we do to accelerate Magento sites
    </h1>
  </section>
  <section-layout name="mfo__first" title="" margin="true">
    <p>
      There are so many reasons why Magento sites might be slow. However, there is always a chance to optimize a Magento site. It’s hard to describe a strict plan for site optimization, because the optimization process  really depends on a number of various factors.
     </p>
     <p>
       General plan for Magento sites optimization is as follows:
    </p>
  </section-layout>

  <section-layout name="mfo__analytics" title="Analytics" margin="true">
    <p>
      We start from the quick site analytics using such 3rd party metrics as https://gtmetrix.com or https://developers.google.com/speed/pagespeed/. These tools allow us to locate those items that slow down the time of  site loading. The factors that slow down sites may generally be divided intoserver and client side issues. They often come together.
    </p>
  </section-layout>

  <section-layout name="mfo__structure" title="Structure analyzing" margin="true">
    <p>
     If we see that some HTML/CSS/Images slow down the site loading, we start investigating the Magento theme structure analyzing.
    </p>
    <p>The issues may be caused by:</p>
    <ul>
        <li>Incorrect theme architecture and not optimized theme structure. The solution is often to rebuild PHTML blocks structure.</li>
        <li>CSS used in theme may be not properly configured. E.g. there are too much inheritances of CSS classes or many attributes are redefined in various classes. Then we offer CSS optimization.</li>
        <li>Not optimal JS is often a cause of slow-loading specific widgets or blocks.</li>
        <li>Some clients use not optimal pictures for sliders, products details, etc.</li>
        <li>Some CSS/JS issues may be caused by 3rd party extensions - then we analyze which exactly extensions cause the problem and suggest the ways of solving this issue.</li>
    </ul>
  </section-layout>

  <section-layout name="mfo__loading" title="Loading time" margin="true">
    <p>The site loading time totally depends on server configuration as well.</p>
    <ul>
      <li>First of all, we inspect whether server configuration is suitable for the installed version of Magento and the size of its DB (number of products in store). If we make sure that the server’s capabilities are not enough to handle Magento instance - we suggest an appropriate server configuration to be used.</li>
      <li>We inspect and tune necessary server environments:
          <ol type="a">
              <li>It’s critical for Magento sites to have MySQL properly tuned  (buffers, number of threads, cache size, etc.)</li>
              <li>PHP should be set properly (allocated memory, buffers size, number of allowed variables, number of allowed threads that strongly depends on server configuration)</li>
              <li>Storage performance is very important for Magento sites optimization. The shared folders should be configured properly.</li>
              <li>Web server should be tuned properly as well (Nginx, Apache, PHP_FPM)</li>
              <li>We also check l vulnerability of the server and whether there are no 3rd party resources demanding the process revolving on the server.</li>
          </ol>
      </li>
      <li>We analyze server’s loading by Magento using profilers (both built-in and 3rd party), that allows us to define which objectsmethods are the most time consuming. Then we define which 3rd party extensions are responsible for those objects and provide recommendations for optimizing such modules. Considering the fact that we are experienced in many 3rd party modules, we have a lot of predefined recommendations.</li>
    </ul>
  </section-layout>
  <section-layout name="mfo__widgetes" title="3rd party widgets" margin="true">
     <p>Many times we faced the situation when clients inserted a lot of various 3rd party analytics to their sites, that slowed down the site’s loading significantly. Therefore, superfluous analytics should be disabled and the necessary one turned properly.</p>
  </section-layout>
  <section-layout name="mfo__settings" title="Cache settings" margin="true">
  <p>Sometimes clients miss proper Magento cache settings. We recommend and set Memcache for a built-in Magento caching mechanism. Full page cache (FPC) may significantly improve site loading as well. We recommend to add and set 3rd party FPC as <a href="https://github.com/GordonLesti/Lesti_Fpc" target="
  _blank">https://github.com/GordonLesti/Lesti_Fpc</a>.</p>
   <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
      goBack(){
        if(!document.referrer){
          Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
          this.$router.push('/magento')
        }else{
          this.$router.go(-1)
        }
      }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo__banner {
  height: 300px;
  position: relative;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 640px;
    margin-bottom: 60px;
  }
  &-img,
  picture {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.mfo__banner-title {
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  max-width: calc(100% - 32px);
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 72px;
    max-width: 1150px;
  }
}
.mfo__first,
.mfo__analytics,
.mfo__structure,
.mfo__loading,
.mfo__widgetes,
.mfo__settings {
  p {
    margin-bottom: 0;
  }
  ul {
    padding-left: 30px;
    margin-bottom: 0;
    ol {
      li {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    li {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: $red;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .section_content {
    flex-direction: column;
    align-items: flex-start;
    overflow-wrap: break-word
  }
  .section_title {
    margin-bottom: 30px;
    @include media(">=tablet") {
      margin-bottom: 60px;
    }
    .title{
      @include media(">=tablet") {
        max-width: 400px;
      }
    }
  }
  .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
          text-decoration: none;
      }
  }
}
.mfo__widgetes{
    .section_title{
        margin-bottom: 0;
    }
}
.mfo__structure{
    ul{
        li{
            margin-bottom:5px;
        }
    }
}
</style>

